import React from "react";
import OutscalAnimatedLogo from "@/Components/OutscalAnimatedLogo";
import { styled } from "styled-components";
import Constant from "@/staticData/constant";
import { RowDiv } from "@/outscal-commons-frontend/Styled";

const Root = styled(RowDiv)`
  width: 100%;
  min-height: 60px;
  height: ${Constant.NAVBAR_HEIGHT};
  top: 0 !important;
  position: sticky;
`;

const NoExitTopBar = () => {
  return (
    <Root justifyContent="space-between" alignItems="center">
      <OutscalAnimatedLogo />
    </Root>
  );
};

export default NoExitTopBar;
